<template>
  <div class="p-grid p-justify-center p-formgrid">
    <div class="p-col-12 p-md-4">
      <div class="card p-fluid">
        <form @submit.prevent="submitForm">
          <div class="p-field">
            <label for="login">Телефон</label>
            <InputMask
              mask="+7-999-999-99-99"
              id="login"
              v-model.trim="login.val"
              :required="true"
              :class="!login.isValid ? 'p-invalid' : ''"
              @blur="clearValidity('login')"
              :autoClear="false"
            />
          </div>
          <div class="p-field">
            <label for="password">Пароль</label>
            <Password
              id="password"
              @blur="clearValidity('password')"
              :class="!password.isValid ? 'p-invalid' : ''"
              v-model.trim="password.val"
              :required="true"
              @keyup.enter="submitForm"
              :feedback="false"
            />
          </div>
          <Button
            label="Вход"
            @click="submitForm"
            :disabled="isLoading"
            id="loginButton"
          ></Button>
        </form>
        <div class="p-mt-4 p-text-center">
          <router-link
            :to="{
              name: 'RestorePassword',
            }"
            >Забыли пароль?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      login: {
        val: "",
        isValid: true,
      },
      password: {
        val: "",
        isValid: true,
      },
      formIsValid: true,
      error: null,
      isLoading: false,
    };
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      this.login.isValid = true;
      this.password.isValid = true;
      if (!this.login.val) {
        this.login.isValid = false;
        this.formIsValid = false;
      }
      if (!this.password.val) {
        this.password.isValid = false;
        this.formIsValid = false;
      }
    },
    async submitForm() {
      this.validateForm();

      if (!this.formIsValid) {
        return;
      }

      this.isLoading = true;

      const actionPayload = {
        login: this.login.val,
        password: this.password.val,
      };

      try {
        await this.$store.dispatch("login", actionPayload);
        this.$router.replace("/");
      } catch (err) {
        this.error = err.message || "Не получилось войти, попробуйте позже";
        // console.error("error!", this.error);
        this.$toast.add({
          severity: "error",
          summary: "Ошибка при входе!",
          detail: this.error,
          life: 6000,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
